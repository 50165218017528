<template>
  <div class="bigBox">
    <div class="box">
      <img class="banner" src="/activity/voucher/futong/banner.png" alt="">
      <div>
        <div id="login" :class="{ shaking: toShake}">
          <img src="/activity/voucher/futong/other.png" alt="">
          <div class="interactive">
            <input v-model="form.phone" type="text" placeholder="请输入手机号" @blur="changePhone">
            <div class="codeBox">
              <input v-model="form.code" type="text" placeholder="请输入验证码">
              <span v-if="verControl" @click="verCode(form.phone)">获取验证码</span>
              <span v-else>重新获取({{verSecond}})</span>
            </div>
            <button @click="submit(form)">立即领取</button>
          </div>
          <span class="dollar">—— 还能领微信红包 ——</span>
          <div v-if="controlImgCodeDialog" class="codeDialog">
            <img :src="imgcodelocal" alt="">
            <p>
              <span>图形验证码:</span>
              <input type="text" v-model="form.codeNumber">
            </p>
            <div>
              <button @click="()=>{this.controlImgCodeDialog = false}">取消</button>
              <button @click="confirm(form.codeNumber)">确定</button>
            </div>
          </div>
        </div>
      </div>
      
    </div>
    <div class="box2">
        <div class="part1">
            <p class="rulesP" @click="rulesModel = true"></p>
        </div>
    
      <img class="part2" src="/activity/voucher/futong/part2.png" alt="">
      <img class="part3" src="/activity/voucher/futong/part3.png" alt="">
      <img @click="live800" class="part4" src="/activity/voucher/futong/button.png" alt="">
    </div>
    <div class="mask" v-if="rulesModel">
      <div class="popup">
        <div class="closeBox">
          <img
            class="guanbi1"
            @click="rulesModel = false"
            src="../../../../static/common/wap/closer.png"
            alt=""
          />
        </div>
        <h3>活动规则</h3>
        <ul class="dialogContent">
          <li>
            <span>活动对象：</span><br/>
            活动期间，在本平台首次开立真实账户的客户
          </li>
          <li>
            <span>活动时间：</span>
            即日起至{{ getLastDayOfMonth() }}
          </li>
          <li>
            <span>活动期间，开户领$588美元赠金</span><br/>
            用户完成首次开户 领$588美元赠金，有效期90天内每交易一手伦敦金/伦敦银，获得相对应的赠金金额返还（未满一手按照实际交易手数占一手的比例计算）
          </li>
          <li>
            <span>奖励明细</span><br/>
            <img style="width:100%" src="/activity/voucher/table.png" alt="" /><br/>
            用户可登录App-我的-赠金奖励中查看
          </li>
          <li>
            <span>注意事项：</span><br/>
            1. 客户参与活动需在本公司开立真实交易账户；参与该活动的新用户，为保证资金安全，在初始L1层级，账户强平比例上调至50%，升级后自动恢复为20%；<br/>
            2. 交易手数计算：开仓一手平仓一手，如此来回算一手；<br/>
            3. 本活动本公司新客户仅有一次机会参与，不可重复领取；<br/>
            4. 本公司保留随时修订、暂停、终止本活动及任何相关规则条款之权利及其解释权。
          </li>
        </ul>
      </div>
    </div>
    <el-dialog
      class="signSucessDialog"
      :visible.sync="signSucessVisible"
      width="92%"
      :close-on-click-modal="false"
    >
      <img class="close" width="28" src="/activity/voucher/futong/close.png" alt="" @click="signSucessVisible = false">
      <div class="signSucessBox">
        <div class="fs16">以下为您的账号信息：</div>
        <div class="text-italic">
          <p>账号：{{ userInfo.Mobile }}</p>
          <p>密码：{{ userInfo.Password }}</p>
        </div>
        <div>您可以打开APP，前往<span class="red">【我的 - 赠金奖励】</span>查看活动奖励。</div>
        <img width="100%" class="welfareIcon" src="/activity/voucher/futong/welfareIcon.png" alt="">
        <div>为了方便交易，我们为您生成 “MT5交易账号” ：</div>
        <div class="text-italic">
          <p>真实交易账号：{{ userInfo.Account }}</p>
          <p>体验交易账号：{{ userInfo.DemoAccount }}</p>
        </div>
        <div class="tips">您可以使用MT5交易账号登录，也可以直接使用登录账号登录。如有疑问，请<span class="red underline" @click="live800">联系客服</span></div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { verifyImgCode, quickRegister, maidianOpenreal } from '../../../../api/info';
import { getGuid } from '../../../../utils/guid';
export default {
  name:'voucherNewWap',
  data(){
    return{
      form:{
        phone:'',
        code:'',
        codeNumber:'',
      },
      signSucessVisible: false,
      // 控制显示验证码或读秒
      verControl:true,
      // 定时器倒计时
      refreshData:null,
      // 验证码读秒数
      verSecond: 60,
      // 控制图片验证码弹窗
      controlImgCodeDialog:false,
      // 图片验证码地址
      imgcodelocal:'',
      guid:'',
      // 是否抖动
      toShake: false,
      scroll: '',
      rulesModel: false,
      packageid: '',
      userInfo: {},
    }
  },
  created(){
    // 传给App.vue
    this.$emit('controlShow',false);
    this.$emit('controlBottom',false);
    this.packageid = this.$route.query.channel || this.$route.query.v || 'HFTY7001';
    maidianOpenreal({ eventname: 'z1', eventdescribe: '真实用户_访客数据', packageid: this.packageid });
  },
  methods:{
    getLastDayOfMonth() {
      const date = new Date();
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      const lastDayOfMonth = new Date(year, month, 0);
      // 获取最后一天的日期
      const lastDate = lastDayOfMonth.getDate();
      return year + '年' + month + '月' + lastDate + '日'
    },
    // 输入手机号
    changePhone(e) {
      if(e.target.value) {
        maidianOpenreal({
          eventname: 'rr2',
          eventdescribe: '真实用户_开户填写手机号',
          detail: {
              mobile: e.target.value,
          },
          eventtype: 'input',
          packageid: this.packageid
        });
      }
    },
    // 点击获取验证码
    verCode(attr){
      if(this.isPhone(attr)){
        this.controlImgCodeDialog = true;
        this.countdowm();
        this.guid = getGuid();
        // this.imgcodelocal = window.location.origin + '/api/proxy/v4/U104?key=' + this.guid;
        this.imgcodelocal = 'https://www.rlcvipltd.net/api/proxy' + '/v4/U104?key=' + this.guid;
      }else{
        this.$message({
          message: '请输入正确手机号!',
          type: 'warning',
        });
      }
    },
    // 提交验证码
    confirm(attr){
      if(attr){
        let data = {
          Mobile: this.form.phone,
          Token: this.guid,
          ImgCode: Number(attr),
          Appcode: this.packageid,
        }
        // 获取图片验证码接口
        verifyImgCode(data).then(res=>{
          if(res.IsSuccess){
            this.$message({
              message: res.Data,
              type: 'success'
            });
            // this.form.code = res.data;
            this.verControl = !this.verControl;
            this.controlImgCodeDialog = false;
            maidianOpenreal({ eventname: 'rr3', eventdescribe: '真实用户_发送验证码', eventtype: 'input', packageid: this.packageid });
          }else{
            this.$message({
              message: res.Data,
              type: 'warning',
            });
            this.controlImgCodeDialog = false;
          }
        })
      }else{
        this.$message({
          message: '请输入图片验证码结果!',
          type: 'warning'
        });
      }
    },
    // 提交手机号验证码
    submit(form){
      if(form.phone && form.code){
        let data = {
          IsQuickRregister:true,
          Mobile:form.phone,
          Code:form.code,
          Token:this.guid,
          LoginType:1,
          AuthMode:1,
          Channel: this.packageid
        };
        maidianOpenreal({ eventname: 'rr4', eventdescribe: '真实用户_提交资料数', eventtype: 'input', packageid: this.packageid });
        quickRegister(data).then(res=>{
          console.log(res);
          if(res.ok == 1){
            this.$message({
              message: res.msg,
              type: 'success',
            });
            maidianOpenreal({
              eventname: 'rr5',
              eventdescribe: '真实开户_开户成功数',
              usertype: '2',
              medium: '',
              plan: '',
              account: res.data.Account,
              accounttype: 2,
              packageid: this.packageid,
            });
            this.userInfo = res.data;
            this.userInfo.DemoAccount = res.data.LinkedAccounts[0].Account;
            this.signSucessVisible = true;
          }else{
            this.$message({
              message: res.msg,
              type: 'warning'
            });
          }
        })
      }else{
        this.$message({
          message: '请输入手机号和验证码！',
          type: 'warning',
        });
        this.handleShake();
      }
    },
    // 读秒
    countdowm() {
      this.refreshData = window.setInterval(() => {
        this.verSecond--;
        if (this.verSecond < 0) {
          //当倒计时小于0时清除定时器
          window.clearInterval(this.refreshData); //清除定时器
          this.verSecond = 60;
          this.verControl = true;
        }
      }, 1000);
    },
    // 正则判断电话号码格式是否正确
    isPhone(TEL) {
      let strTemp = /^1[3|4|5|6|7|8|9][0-9]{9}$/;
      if (strTemp.test(TEL)) {
        return true;
      }
      return false;
    },
    // 控制抖动
    handleShake() {
      this.toShake= true
      // demo for next animation.
      setTimeout(() => {
        this.toShake= false
      }, 1000)
    },
    // 跳客服
    live800(){
      window.open(this.$parent.live800Url)
      // window.open('https://chat32.live800.com/live800/chatClient/chatbox.jsp?companyID=215184&configID=35519&jid=5377035377')
    },
  },
  watch:{
    'form.phone'(newval){
      // console.log(newval);
    },
    // 立即执行
    immediate: true,
  }
}
</script>

<style lang="less" scoped>
.bigBox{
  height: auto;
  background: #B50B0C;
  
  .box{
    // background: url('/activity/voucher/futong/banner.png') no-repeat;
    background-size: 100% 100%;
    position: relative;
    .banner {
      width: 7.5rem;
    }
    #login{
      width: 6.9rem;
      height: 5.36rem;
      background: #fff;
      // position: absolute;
      border-radius: 11px;
      box-shadow: 3.41px 3.66px 12px 5px rgba(125,119,247,0.11);
      // top: 28.5%;
      // left: 50%;
      // transform: translate(-50%,0);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: -2.8rem auto 0.5rem;
      position: relative;
      z-index: 2;
      p{
        font-size: 0.36rem;
        font-weight: 600;
        color: #ff8038;
        margin: 0rem auto 0.25rem;
      }
      img{
        width: 5rem;
        margin-bottom: 0.25rem;
      }
      .interactive{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        input{
          width: 80%;
          height: 0.78rem;
          background: #fff;
          border: 1.5px solid #E6E6E6;
          border-radius: 36px;
          font-size: 0.2rem;
          margin-bottom: 0.28rem;
          text-indent: 0.3rem;
        }
        .codeBox{
          width: 100%;
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
          span{
            width: 1.9rem;
            height: 0.83rem;
            line-height: 0.85rem;
            font-size: 0.24rem;
            border-radius: 36px;
            background-color: #FF1220;
            text-align: center;
            color: #fff;
            position: absolute;
            top: 1px;
            right: 32px;
          }
        }
        button{
          width: 72%;
          height: 0.8rem;
          background-color: #FF1220;
          border: 1px solid #FF1220;
          border-radius: 38px;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #fff;
          font-size: 0.3rem;
        }
      }
      .dollar{
        font-size: 0.30rem;
        color: #FF1220;
        margin-top: 10px;
      }
      .codeDialog{
        width: 95%;
        height: 4.5rem;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        background-color: #fef6e9;
        border-radius: 15px;
        z-index: 99;
        border: 1px solid #ffd8a6;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        img{
          width: 3rem;
          margin-bottom: 0;
        }
        p{
          span{
            color: #7f78fa;
            font-size: 0.32rem;
            font-weight: 600;
          }
          input{
            width: 2.5rem;
            height: 0.8rem;
            background-color: #ffffff;
            border: none;
            margin-left: 0.2rem;
            text-indent: 0.2rem;
            color: #7f78fa;
          }
        }
        div{
          display: flex;
          justify-content: center;
          align-items: center;
          button{
            width: 2rem;
            height: 0.8rem;
            border: 1px solid #ff8038;
            background-color: #ffffff;
            color: #ff8038;
            font-size: 0.3rem;
            border-radius: 8px;
            margin: 0 10px;
          }
          button:nth-child(2){
            background-color: #ff8038;
            color: #ffffff;
          }
        }
      }
    }
    .shaking {
      animation-delay: 0s;
	    animation-name: shake;
	    animation-duration: 0.1s;
	    animation-iteration-count: 3;
	    animation-direction: normal;
	    animation-timing-function: linear;
    }
    @keyframes shake {
	    0% {
        margin-left: 0.3rem;
        margin-right: 0.35rem;
        margin-top: -2.8rem;
	    }
	    100% {
        margin-left: 0.35rem;
        margin-right: 0.3rem;
        margin-top: -2.75rem;
	    }
	  }
    
  }
  .box2{
    position: relative;
    padding-bottom: 1rem;
    .part1{
      width: 7.2rem;
      height: 7.54rem;
      margin: 0 auto;
       background: url('/activity/voucher/futong/part1.png') no-repeat;
       background-size: cover;
       position: relative;
       .rulesP{
        position: absolute;
        width: 1.2rem;
        height: .2rem;
        top: .95rem;
        left: 50%;
        transform: translate(-50%,-50%);
        font-size: 0.35rem;
        font-weight: 600;
        z-index: 2;
        }
    }
    .part2{
      width: 7.2rem;
      display: block;
      margin: 0 auto;
      margin-top: 0.3rem;
    }
    .part3{
      width: 7.2rem;
      display: block;
      margin: 0 auto;
    }
    .part4{
      width: 6.8rem;
      display: block;
      margin: 0 auto;
      margin-top: 0.2rem;
    }
  }
  .tabNew{
    width: 7.50rem;
    height: 1.78rem;
    background: url('../../../../static/common/wap/tab.png') no-repeat;
    background-size: cover;
    box-sizing: border-box;
    padding: 0 0.1rem 0 0.1rem;
    // position: relative;
    .tabTop{
      width: 100%;
      height: 1.02rem;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      div{
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        ul{
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 0;
          .hongshi{
            width: 0.75rem;
            margin: 0 0.2rem;
          }
          p{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            font-size: 0.12rem;
          }
        }
      }
    }
    .tabBottom{
      font-size: 0.10rem;
      color: #fff;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      box-sizing: border-box;
      padding-left: 0.2rem;
    }
    .closer{
      width: 0.24rem;
      position: absolute;
      top: 5px;
      right: 5px;
    }
  }
  .bottomBar{
    width: 7.50rem;
    box-sizing: border-box;
    padding: 0.25rem 0;
    background-color: #fff0ed;
    position: fixed;
    bottom: 0;
    p{
      width: 85%;
      height: 0.78rem;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 auto;
      img{
        width: 0.37rem;
        margin-right: 0.1rem;
      }
      span{
        font-size: 0.3rem;
      }
    }
    p:nth-child(1){
      border: 1px solid #d52226;
      border-radius: 0.5rem;
      margin-bottom: 0.2rem;
      span{
        color: #d52226;
      }
    }
    p:nth-child(2){
      background: url('../../../../static/common/wap/botBtn.png') no-repeat;
      background-size: 100% 100%;
      span{
        color: #fff;
      }
    }
  }
  .mask {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    top: 0;
    z-index: 3;
  }
  .popup {
    width: 95%;
    height: 11.5rem;
    border-radius: 0.1rem;
    background: #fff;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%;-50%);
    box-sizing: border-box;
    padding: 10px;
    box-shadow: 0px 0px 95px 0px rgba(43, 53, 105, 0.1);
    overflow-y: scroll;
    .closeBox {
      height: 0.5rem;
      .guanbi1 {
        float: right;
        width: 0.35rem;
        cursor: pointer;
        margin-right: 0.1rem;
      }
    }
    h3{
      font-size: 0.32rem;
      margin: -0.3rem auto 0rem;
      text-align: center;
      color: #6a32aa;
    }
    .dialogContent {
      box-sizing: border-box;
      padding: 0.2rem 0.1rem;
      li {
        font-size: 0.24rem;
        color: rgb(31, 30, 30);
        line-height: 0.5rem;
        span {
          font-weight: 600;
          font-size: 0.28rem;
          color: #6a32aa;
        }
      }
    }
  }
  ::-webkit-scrollbar {
    width: 0px;
  }

  ::-webkit-scrollbar-track {
    background-color: none;
  }

  ::-webkit-scrollbar-thumb {
    background-color: none;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: none;
  }

  ::-webkit-scrollbar-thumb:active {
    background-color: none;
  }
  ::v-deep .signSucessDialog {
    .el-dialog {
      background: url('/activity/voucher/futong/signSucessBg.png') no-repeat;
      background-size: 100% 100%;
      box-shadow: none;
      .el-dialog__header {
        display: none;
      }
      .el-dialog__body {
        padding: 4.2rem 1% 0.6rem;
        font-size: 0.24rem;
        text-align: center;
        .text-italic {
          font-weight: 600;
          font-style: italic;
          color: #000;
          font-size: 0.3rem;
          margin: 0.15rem 0 0.2rem;
          p {
            margin: 0;
          }
        }
        .red {
          color: #ff1220;
        }
        .fs16 {
          font-size: 0.28rem;
        }
        .underline {
          text-decoration: underline; /* 添加下划线 */
        }
        .close {
          position: absolute;
          top: 0.45rem;
          right: 0.2rem;
          z-index: 12;
          cursor: pointer;
        }
        .welfareIcon {
          margin-top: 0.08rem;
          margin-bottom: -0.08rem;
        }
        .tips {
          text-align: left;
          padding: 0 0.35rem;
        }
      }
    }
  }
}
</style>